#workshops {
    padding: 4vw 0;
    background-color: #ef3f3d;
    
}
#workshops h2, #workshops h3, #workshops p {
    color: white;
}

#workshops h2, #workshops h3, #workshops p {
    margin: 0;
}
#workshops h3 {
    font-size: clamp(16px, 1.8vw, 24px);
    text-transform: uppercase;
}

.workshop-head {
    color: white;
}

.workshop-each {
   color:white;
   font-size: clamp(10px, 1vw, 16px);
   border-bottom: 1px solid white;
   padding: 2vw 0;
}
.workshop-each:first-of-type {
    padding-top: 0;
}
