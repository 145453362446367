@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

#navigation {
  background-color: white;
  position: sticky !important;
  top: 0;
  z-index: 10;
  transition: .5s;
}

#navigation > div {
  width:  100%;
  height:  100%;
  color: #182A40;
  align-items: center;
}
#navigation > div > div {
  height:  100%;
}
#navigation.small {
  box-shadow: 0 5px 10px rgba(0,0,0,.35);
  height: auto;
}

#navigation.small ul {
  height: auto;
}

#navigation #logo {
  display: flex;
  align-items: center;
 
}

#navigation #logo img{
  /* transform:  translateY(10px); */
  width: 10vw;
  transition: .45s;
  position: absolute;
  top: 0;

  }
#navigation #logo #logoname {
  text-transform: uppercase;
  color:  #6583a8;
  font-weight: 600;
  font-size: .875rem;
  transition: .45s;
  margin-left:  100px;
}
#navigation #logo #logoname span {
  color: #9fb8d8;
}

#navigation.small #logo #logoname {
  transform:  translateY(0);
  font-size:  .75rem;
  margin-left:  90px;
}


#navigation.small #logo{
  transform:  translateY(0);
}

#navigation.small #logo img {
  width:  80px;
  /* transform: translateY(10px); */
  cursor: pointer;
}  
#navigation ul {
 text-align: right;
 position: relative;
 margin: 0;
 display: flex;
 align-items: center;
 flex: 1;
 flex-direction: row;
 justify-content: flex-end;
 align-items: center;

}

#navigation ul li {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom:  0;
  align-items: stretch;
  height:  100%;
  
}
a.navlink {
  color: #182a40;
}
#navigation ul li a.navlink {
  color: #182A40;
  text-transform: uppercase;
  text-decoration: none;
  font-size: clamp(10px, .875vw, 20px);
  padding: clamp(5px, 10px, 10px);
  transition: .5s;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  display:  flex;
  align-items: center;

}

#navigation .navlink-active {
  color: white;
  background-color: rgba(255,255,255,.25);
}

#navigation ul a:hover {
  color: white !important;
  background-color: rgb(130, 130, 130);
} 

.hamburger {
  position: relative;
  width:50%;
   transition:all 0.5s ease;
}

.hamburger button {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  cursor: pointer;
  background:  #b9b9b9;
}

.#navigation .menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: darkcyan;
  right: 0;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
  transition:all 0.5s ease;
}


#navigation nav.hamburger ul.menuNav, #navigation nav.hamburger ul.menuNav li {
  display: none;
  transition:  all .5s ease;
}
#navigation nav.hamburger ul.menuNav.showMenu {
  display: list-item;;
  list-style: none;
  width: 100%;
  height:  auto;
  background-color: rgba(155,245,245,.9);
  position: fixed;
  right: 0;
  top: 0;
  padding-right: 80px;
  direction:  rtl;
}

#navigation nav.hamburger ul.menuNav.showMenu li{
  list-style: none;
  display: list-item;
  text-align: right;
  width: 50%;
  

}


#navigation nav.hamburger ul.menuNav.showMenu li a {
  color: #666;
  border-bottom: 1px dotted #999;
  text-align:  right;
  width: 100%;
  display: block;

}


