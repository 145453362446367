#footer {
    background-color: #182A40;
    height: 10vw;
    display: flex;
    align-items: center;
    
}
#footer div {
    color: white;
    align-items: center;
    text-align: left;
}

#footer a {
    color: teal;
}