#ic2s2 {
    background-color: #182A40;
    
}
#ic2s2 h2  {
    color: white;

}

#ic2s2 .indicator {
    /* width: clamp(12px, 1vw, 20px); */
    color: white;
    height: auto;
    /* text-indent: 30000px; */
    padding: 5px;
    margin-right: 2px;
    position: relative;
    font-size: clamp(16px, 3vw, 40px);
}
#ic2s2 .indicator.active {
    color: cyan;
}

#ic2s2 .indicator:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,.1);
}

#ic2s2 .slide-container.buttons button {
    background-color: inherit;
    padding: 10px; 
    margin-bottom: 1rem;
    border: 1px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    transition: .25s all;
}
#ic2s2 .slide-container.buttons button:hover {
    background-color: #8cc0f1;
}
#ic2s2 .slide-container.buttons button:nth-of-type(1) {
    margin-right: 2rem;
}

.react-slideshow-fadezoom-wrapper .react-slideshow-fadezoom-images-wrap {
    /* height: 660px; */
}