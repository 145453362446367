#video {
    padding: 4vw 0;
}
#video .mission {
    /* display: flex; */
    align-items: center;
}
#video h2 {
  color: black;
}
#video .mission p {
    font-size: clamp(16px, 1.75vw, 24px);
    padding-right: 1vw;
    font-weight: 300;
    line-height: 1.8;
}
[style*="--aspect-ratio"] > :first-child {
    width: 100% !important;
    height: 100% !important;
  }
  [style*="--aspect-ratio"] > img {  
    height: auto;
  } 
  @supports (--custom:property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }  
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }  
  }
