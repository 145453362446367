.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

a {
  color: #61dafb;
}

p {
  line-height: 160%;
}


#hero{
  min-height: 330px;
  height: 40vh;
  font-size: calc(10px + 2vmin);
  color: white;
  /* background-image: url(./img/soundwave-teal.png); */
  background: linear-gradient(90deg,#24233e,#145e81 35%,#00d4ff);
  background-image: linear-gradient(90deg, rgb(36, 35, 62), rgb(20, 94, 129) 35%, rgb(0, 212, 255));
  display: flex;
  align-items: center;
  position: relative;
}
#hero::before {
  content: '';
  position: absolute;
  background-image: url(./img/soundwave-teal.png), url(./img/waves-gold.svg);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  background-position: 0;
  opacity: .5;

}

#hero div div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: clamp(12px, 1.5vw, 20px);
  line-height: 1.6;
}
#hero h2, #hero p{
 text-align: center;
}
#hero h2 {
  color: white;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0;
}

.App-link {
  color: #61dafb;
}
#video {
  background-color: #fcb94c;
}



#events {
  padding: 2vw 0 2vw 0;
}

h2{
  font-size: clamp(24px, 4vw, 46px);
  color: #164460;
  line-height: auto;
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-weight: 900;
  line-height: 1.2;
}
h3 {
  font-family: Oswald, sans-serif;
  font-weight: 900;
  font-size: clamp(18px, 2.6vw, 36px);
}
#events h2, #events h4, #events p{
  color: #165560
}
#events h3 {
  font-size: clamp(16px 1.4vw, 30px);
  color: #892232;

}
#summit img, #humanai img {
  width: 100%;
}
#humanai img{ 
background-color: #830000;}

#humanai img {
  width: 75%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
}


#summit button, #humanai button{
      padding: 10px;
       border: 2px solid #830000;
       font-size: 16px;
       text-transform: uppercase;
       text-decoration: none;
       background-color: inherit;
   }

#summit button a, #humanai button a{
  text-transform: uppercase;
  text-decoration: none;
  color: #830000;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 768px) {
  #conf img {width: 25%;}
}