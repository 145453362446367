.research {
    background-color: #00b5b8; /* #91c740;*/
    padding: 1vw 0 2vw 0;
    color: white;
}
#research2 {
    background-color: white;
    justify-content: center;
    padding: 2vw;
}
.research h2 {
    margin-bottom: 0;
}
.research h2 + p {
    font-size: clamp(16px, 1.75vw, 24px);
    padding-right: 3vw;
    font-weight: 300;
    line-height: 1.8;
}

.research h3 {
    font-size: clamp(14px, 2.6vw, 28px);
    color: white;
    margin-bottom: 0;

}
.icon {
    width: 3vw;
    text-align: center;
    align-items: center;
}
.iconcontainer {
    padding: 0 2rem !important;
    border-right: 1px solid white;

}
.iconcontainer:last-child {
    border-right: inherit;

}
.iconcontainer > div{
    align-items: center !important;
    display: block !important;
}
