
.people-each {
    padding-bottom: 1vw;
    display: flex;
    flex-direction: column;
}
.people-each > div{
    position: relative;
    margin: 1vw auto;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 80%;
    flex-grow: 1;
}  
.people-each h3 {
    font-family: Oswald;
    font-size: clamp(16px, 2vw, 30px);
    font-weight: 900;
    margin: 0 auto;
}

.people-each h3, .people-each p {
    position: relative;
    z-index: 1;
    color: white;
}
.people-each p {
    font-size: clamp(9px, 1vw, 20px);
}
.role {
    text-transform: uppercase;
    margin: 0 auto;
}
.title {
    margin: .5vw auto;
    line-height: 1.5;
}
p.description {
    margin-top: 1.5vw;
    margin-bottom: 4vw;
    line-height: 1.5;
}

.people-each > div > .container {
    width: 50%;
    margin-left: 1vw;
    background-color: #00B5B8;
    z-index: 1;
    height: 100%;
    padding: 1vw 1vw 0;
    flex-direction: column; 
}

#additionals .people-each > div {
    background: #ef3f3d;
    position: relative;
    height: 100%;
}
#additionals .people-each img {
   position: absolute;
   width: 33.3333%;
   top: 2vw;
   left: 1vw;

}
#additionals .people-each div > .container {
    background-color: inherit;
    margin-left: 38.3333%;
    width: auto;
    margin-top: 0;
    padding-top: 0;
    padding-right: 1vw;
    padding-bottom: 1vw;
    height: auto;
}
#additionals .people-each div > .container h3{
 font-weight: 900;
 margin: 1.5vw 0 .5vw;
 font-family: Oswald;
}
#additionals .people-each p{
    font-size: clamp(11px, .75vw, 20px);
    margin: .25vw 0;
    line-height: 1.4;
}
#additionals .people-each p.role {
    font-size: clamp(15px, 1.4vw, 24px);
    text-transform: uppercase;
}

@media only screen and (max-width: 575px) {

    #additionals .people-each div > .container {
        height: 170px;
    }
}