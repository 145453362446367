#papers {
    border-top: 2px solid #ededed;
    padding: 2vw 0;
}


.paper {
    margin-bottom: 5vw;
    
}
.paper > div{
    border: 1px solid #c4c4c4;
    background: #fafafa;
    height: 100%;
    padding: 2vw;
    font-size: clamp(12px, .9vw, 40px);
    line-height: 1.4;
    display: flex;
    flex-direction:column;
    color: #676767;
    
}
.paper a {
    color: #EE403F;
    font-weight: 900;
}
.paper > div cite {
    font-size:clamp(11px, .8vw, 30px);
    margin-top: auto;
}